import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import SZFModal from './szf-modal.component';

export interface IImageGalleryProps {
    className: string;
    images: string[];
    textDirection: string;
}

export interface ICarouselState {
    display: boolean;
    filteredImages?: string[]
}

export const ImageGallery = (props: IImageGalleryProps) => {
    const slider = useRef<Slider>(null);
    const { images, className, textDirection } = props;
    const [carousel, setCarousel] = useState<ICarouselState>({
        display: false,
        filteredImages: images
    });
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        arrows: true,
        [textDirection]: true
    };

    const handleShowCarousel = (e: React.MouseEvent<HTMLElement> | undefined) => {
        setCarousel({
            display: !carousel.display,
            filteredImages: carousel.filteredImages
        });
        slider.current?.slickGoTo(Number(e?.currentTarget.id), true);
    };

    const hideBrokenImage = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const image = e.currentTarget;
        const brokenImageIndex = images?.indexOf(image.src.replace(/%20/g,' '));
        carousel.filteredImages?.splice(brokenImageIndex, 1);
        const newfilteredImages = carousel.filteredImages
        newfilteredImages?.splice(brokenImageIndex, 1);
        setCarousel({
            ...carousel,
            filteredImages: newfilteredImages
        })
    };

    return (
        <>
            <div className={`${className}__gallery-container`}>
                {carousel.filteredImages?.map((imageUrl: string, index) => {
                    return (
                        <>
                            <div className={`${className}__gallery-img`}>
                                <img id={String(index)} src={imageUrl} onClick={(e) => { handleShowCarousel(e); }} onError={(e) => { hideBrokenImage(e); }} />
                            </div>
                        </>
                    );
                })}
            </div>
            {
                <SZFModal
                    isOpen={carousel.display}
                    onModalToggle={handleShowCarousel}
                    className={'single-image-modal'}
                    modalTitle={
                        <button onClick={handleShowCarousel} className='modal-close-btn'>
                            <img
                                src='https://images-ap-prod.cms.commerce.dynamics.com/cms/api/rwrswxpkjb/imageFileData/MB2m2E?pubver=0'
                            />
                        </button>
                    }
                    modalBody={
                        <Slider {...settings} ref={slider}>
                            {carousel.filteredImages?.map((imageUrl: string, index: number) => {
                                return (
                                    <>
                                        <div className={`${className}__gallery-img`}>
                                            <img id={String(index)} src={imageUrl} />
                                        </div>
                                    </>
                                );
                            })}
                        </Slider>
                    }
                />
            }
        </>
    );
};